import moment from 'moment';

const { REACT_APP_API_URL, REACT_APP_SITE_URL } = process.env;
export const SITE_URL = REACT_APP_SITE_URL;
export const API_URL = REACT_APP_API_URL;
export const DEFAULT_PAST_START_DATE_ANALYTICS = moment().utc().clone().weekday(-13).startOf('day');
export const DEFAULT_PAST_END_DATE_ANALYTICS = moment().utc().clone().weekday(-7).endOf('day');
export const DEFAULT_START_DATE_ANALYTICS = moment().utc().clone().weekday(-6).startOf('day');
export const DEFAULT_END_DATE_ANALYTICS = moment().utc().clone().weekday(0).endOf('day');
export const CSV_REPORT_FORMAT = 'DD/MM';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const TIME_FORMAT = 'HH:mm:ss.SSS';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm:ss`;
export const DATE_HOUR_FORMAT = `HH:mm`;
export const EXAMPLE_CONTENT_UPLOAD_URL = 'https://drive.google.com/file/d/1dohuhfyzcXL1vXt08hZBfdYsF1hTpJHD/view?usp=sharing';
export const UPLOAD_CONTENT_URL = '/admin/contents/upload';
export const UPDATE_CONTENT_URL = '/admin/contents/update';
export const UPLOAD_EPISODE_URL = '/admin/upload_episodes';
export const UPLOAD_REPORT_URL = '/reports/upload';
export const PROMOTION_LIST_URL = '/cms/promotions';
export const PROMOTION_URL = '/promotions';
export const EMAIL_CONTACT_URL = '/email_contact';
export const EMAIL_CONTENT_URL = '/email_content';
export const GROUP_URL = '/groups';
export const GROUP_CHANNEL_URL = '/group_channels';
export const GROUP_PLAYLIST_URL = '/group_playlists';
export const CHANNEL_URL = '/channels';
export const IMPORT_FEED_TO_GROUP = '/feeds/:feedId/import_to/:channelId';
export const TRENDING_URL = '/trending';
export const CHANNEL_EXTERNAL_CHANNEL_URL = '/feeds/channels';
export const VDMS_TAG_URL = '/vdms_tags';
export const VDMS_VIDEO_URL ='/vdms_videos';
export const VERIFY_VIDEO_URL ='/vdms_videos/:id/verify_database';
export const DELETE_VDMS_VIDEO_URL = '/vdms_videos/:id/delete_from_vdms';
export const USER_ACCOUNT_URL = '/account';
export const TAG_URL = '/tags';
export const CONTENT_URL = '/contents';
export const CONTENT_TERRITORY_COUNTER_URL = '/content_territories/counter';
export const CONTENT_TERRITORY_URL = '/content_territories';
export const MRSS_FEED_CONTENT_URL = '/mrss/contents';
export const SEARCH_URL = '/admin/search';
export const MINOR_CONTENT_URL = '/admin/contents/minor_content';
export const GENRE_URL = '/genres';
export const MRSS_FEED_URL = '/mrss_feeds';
export const COUNTRY_URL = '/countries';
export const NOTIFICATION_URL = '/notifications';
export const SEND_NOTIFICATION_URL = '/notifications/send_message';
export const DEVICE_URL = '/devices';
export const EPISODE_URL = '/episodes';
export const ADD_SEASON_TO_CONTENT_URL = '/seasons/add_content';
export const ADD_EPISODE_TO_SEASON_URL = '/seasons/add_episode';
export const SEASON_URL = '/seasons';
export const TERRITORY_URL = '/territories';
export const UPLOAD_URL = '/upload';
export const LANGUAGE_URL = '/languages';
export const UPLOAD_FILE_URL = '/upload_file';
export const SIMILAR_TITLE_URL = '/similar_titles';
export const RIGHTS_HOLDER_URL = '/rights_holders';
export const RIGHTS_HOLDER_GROUP_URL = '/rights_holder_groups';
export const EXTERNAL_CHANNEL_URL = '/aggregator_feeds';
export const EXTERNAL_CHANNEL_ALL_URL = '/aggregator_feeds/all';
export const IMPORT_EXTERNAL_CHANNEL_URL = '/aggregator_feeds/import';
export const IMPORT_EXTERNAL_CHANNEL_LIVE_URL = '/aggregator_feeds/import_channel';
export const FEED_URL = '/feeds';
export const WEEKLY_FEED_URL = '/weekly_feeds';
export const PROTOCAL = window.location.protocol.replace(':', '%3A');
export const ASSIGN_HOLDER_TO_REPORT_URL = '/reports/assign_holders';
export const USER_AGENT_URL = '/system_monitor/user_agents'
export const REPORT_URL = '/reports';
export const RIGHT_URL = '/rights';
export const HISTORY_URL = '/histories';
export const PLATFORM_URL = '/platforms';
export const IMPORT_CONTENT_XML_URL = '/admin/contents/import_content'
export const COLLECTION_URL = '/collections';
export const RATING_URL = '/ratings';
export const PAGE_URL = '/pages';
export const LINK_URL = '/links';
export const SMART_ADBREAK_POINT_JOB_URL = '/smart_adbreak_points';
export const SMART_ADBREAK_POINT_START_ANALYSIS_VIDEO_URL = '/smart_adbreak_points/start_analysis_video';
export const USER_URL = '/users';
export const USER_COMPANY_URL = '/users/company';
export const APP_STATISTICS_URL = '/statistics/app_statistics';
export const USER_STATISTICS_URL = '/statistics/user_statistics';
export const ROLE_URL = '/roles';
export const TRANSCODE_URL = '/transcodes';
export const FINALVIDEOENCODE_URL = '/finalvideoencodes';
export const TRANSCODE_TASK_URL = '/transcodes/tasks';
export const TRANSCODE_MEZZANINE_TASK_URL = '/transcodes/mezzanine/tasks';
export const TRANSCODE_MEZZANINE_LOW_RES_TASK_URL = '/transcodes/mezzanine/low_res_tasks';
export const LOGIN_URL = '/auth/login';
export const SETTING_URL = '/settings';
export const SETTING_SINGLE_URL = '/settings/single';
export const VAST_TAG_URL = '/vast_tags';
export const ADVERTISING_URL = '/advertising';
export const ADVERTISING_STATIC_FILE_URL = '/advertising/static_file';
export const PROMOTION_CONTENT_URL = '/promotions/contents';
export const PROFILE_URL = '/profile';
export const MY_PERMISSION_URL = '/profile/my_permissions';
export const VDMS_URL = '/vdms';
export const VDMS_ASSET_URL = `${VDMS_URL}/asset`;
export const SUBTITLE_URL = '/subtitles_20';
export const CONTRACT_TEMPLATE_URL = '/contract_templates';
export const LOG_URL = '/logs';
export const AUDIENCE_STATISTICS_URL = '/statistics/audience_statistics';
export const AUDIENCE_IMPORTER_URL = '/import_statistics/audience_statistics';
export const VIDEO_HOV_URL = '/statistics/video_statistics';
export const VIDEO_IMPORTER_URL = '/import_statistics/video_statistics';
export const TOP_TRENDING_CONTENT_URL = '/statistics/top_trending_contents';
export const EXPORT_TRENDING_CONTENT_URL = '/statistics/export_trending_contents';
export const CONTENT_STATISTICS_URL = '/statistics/content_statistics';
export const CONTENT_IMPORTER_URL = '/import_statistics/content_statistics';
export const LINEAR_CHANNEL_STATISTICS_URL = '/statistics/linear_channel_statistics';
export const LINEAR_CHANNEL_IMPORTER_URL = '/import_statistics/linear_channel_statistics';
export const DOWNLOAD_STATISTICS_URL = '/statistics/download_statistics';
export const DOWNLOAD_IMPORTER_URL = '/import_statistics/download_statistics';
export const FORM_DATA_URL = '/form_data';
export const BRAND_URL = '/brands';
export const UNASSIGNED_RIGHTS_HOLDERS = '/reports/holders';
export const CONTENT_FILM_TITLE = '/admin/contents/film_title';
export const FLOWPLAYER_TOKEN = 'eyJraWQiOiJaNVl0OE9oNFdEeFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NCxcImlkXCI6XCJaNVl0OE9oNFdEeFBcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.hPnRyUZeBzVntcZQk3Iz31oFJMeHvrurcqhfhjfsPcd6CNh_ZZanvsgkne8mxZUD1dp2zKUeByCf4PTqLmyjuQ';
export const TIERS_URL = '/playlist_tiers';
export const FEATURED_CHANNELS_URL = '/featured_channels';
export const APP_VERSION_URL = '/app_versions';
export const PUBLICA_URL = '/publica';
export const REWRITE_SCHEDULES_URL = '/groups/rewrite_schedules';
export const IMPORT_EPG_SCHEDULE_URL = '/groups/import_epg_schedules';
export const SEARCH_TAG_URL = '/search/tags';
export const VDMS_CHECK_TOKEN = '/vdms/check_token';
export const MFA_VERIFY_OTP_URL = '/mfa/verify_otp'
export const MFA_DISABLE_URL = '/mfa/disable';
export const UPLOAD_TEMPLATE_URL = 'https://docs.google.com/spreadsheets/d/103n1v-K7IbmlA2jeJsIEXDwNvIluQioAI4o-_7IEa50/edit#gid=376115861';
export const SMART_ADBREAK_POINT_STATUS_URL = 'https://us-west-2.console.aws.amazon.com/states/home?region=us-west-2#/statemachines/view/arn%3Aaws%3Astates%3Aus-west-2%3A257104460049%3AstateMachine%3ASmartBreakPointTestWorkflow-nfag5r';
export const EDIT_CONTENT_TAB_PERMISSION = {
  ROLE_ADMIN: ['main', 'description', 'info', 'images', 'video', 'seasons', 'distribution', 'advertising', 'history'],
  ROLE_SERIES_CONTENT: ['main', 'description', 'info', 'images', 'seasons', 'distribution', 'history'],
  ROLE_ADD_NEW: ['main', 'description', 'info', 'images', 'seasons', 'distribution'],
  ROLE_ADVERTISING: ['video', 'advertising']
}
export const PLATFORM = {
  web: 'Web',
  ios: 'iOS',
  android: 'Android',
  android_tv: 'Android TV',
  fire_tv: 'Fire TV',
  roku_tv: 'Roku TV',
  mansa: 'Mansa',
  vizio: 'Vizio',
  plex: 'Plex',
  samsung: 'Samsung',
  samsung_tv: 'Samsung TV',
  samsung_smart_monitor: 'Samsung Smart Monitor',
  frequency: 'Frequency',
  familyhub: 'FamilyHub',
}
export const SELECTED_CONTENT_FIELDS = [
  'verenda_ID',
  'film_title',
  'description_en',
  'description_es',
  'production_year',
  'country',
  'director',
  'cast',
  'rights_holders',
  'inactive',
];
export const CONTENT_FIELDS = [
  'slug',
  'cast',
  'country',
  'description_en',
  'description_es',
  'description_200_en',
  'description_200_es',
  'description_400_en',
  'description_400_es',
  'film_type',
  'original_language',
  'dubbed_language',
  'keywords',
  'metadata_notes',
  'writer',
  'suggested_rating',
  'release_date',
  'director',
  'film_title',
  'language',
  'production_year',
  'runtime',
  'source_video_file',
  'source_mezzanine_file',
  'third_party_platforms',
  'ad_breaks',
  'social_links',
  'marketing_information',
  'finalvideoencodes',
  'rights_holders',
  'genres',
  'groups',
  'territories',
  'devices',
  'similar_titles',
  'content_start_date',
  'content_end_date',
  'premium',
  'veranda_ID',
  'alt_ID',
  'inactive',
  'content_live',
];

/**
 * AWS Credentials
 * @type {string}
 */
export const SENTRY_DSN = 'https://9821b9532b2adb6b09a18fb7134ce2fb@o955590.ingest.us.sentry.io/4507575897292800'
export const AWS_KEY_ID = 'AKIATXXE6XEIUUL74KQV';
export const AWS_SECRET_KEY = '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';
export const AWS_ENDPOINT = 's3-us-west-2.amazonaws.com';
export const AWS_PREFIX = 'Distribution_Mezzanine_Files/';
export const AWS_BUCKET = 'butacasource';
export const AWS_REGION = 'us-west-2';
